import React, { useContext } from "react"
import { Link } from "gatsby"
import Template from "../../components/template"
import "../../styles/kooperationspartner.css"
import { femphilsContent } from "../../content/kooperationen/femphils"
import { SettingsContext } from "../../contexts/settings"

const FemphilsPage = () => {
    const [settings, ] = useContext(SettingsContext)
    const content = femphilsContent[settings.language]
    return (
        <Template currentPage="cooperations">
            <section>
            <div>
                <h1>
                    <Link to="/kooperationen" class="back-arrow">{content.arrow}{content.headline}</Link>
                </h1>
                <h2>{content.secondHeadline}</h2>

                <div className="pic-text-femphils">
                    <div className="pic-femphils">
                        <img className="logo-femphils" src="/logos/Kooperationspartner/Logo_femphils.png" alt="Logo der feministischen Philosophinnen"/>
                    </div>
                    <div className="text-femphils">
                        <p>
                            {content.first}
                        </p>
                        <p className="end-of-site">
                            {content.second}
                            <a className="link" href="https://femphil-frankfurt.de/" target="_blank" rel="noopener noreferrer">{content.secondLink}</a>
                            {content.secondSecond}
                        </p>
                    </div>
                </div>
                
            </div>    
            </section>
        </Template >
    )
}

export default FemphilsPage