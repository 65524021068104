const femphilsContent = {
    german: {
        arrow: "<< ",
        headline: "KOOPERATIONEN",
        secondHeadline: "femphils*",
        first: "Bei den femphils* ist die „nächtlichen Idee“ von Nocturnal Unrest entstanden. Die Gruppe ist ein Zusammenschluss feministischer Philosoph:innen an der Goethe-Universität Frankfurt, die sich mit philosophischer Theorie und Praxis sowie mit gesellschaftspolitischen Fragen aus feministischer Perspektive auseinandersetzen. Feministische Philosophie verstehen die femphils* nicht nur als theoretische Auseinandersetzung, sondern als eine Perspektive auf die Welt, die zum Handeln anregt. Zentral für ihre Bearbeitung philosophischer Fragen ist die queer-feministische Perspektive, mit der sie Theoreme analysieren aber auch in die gegenwärtige Praxis der philosophischen Wissenschaft intervenieren. Die femphils* verstehen sich als eine in allen Hinsichten nicht abgeschlossene und sich uneinige Gruppe, in der verschiedene Perspektiven und Hintergründe zusammenlaufen. Einerseits versuchen die femphils* in das Lehrangebot der Universität einzugreifen, indem sie Seminare, Workshops und Lesekreise zu feministischen Themen organisieren. Andererseits sind sie auch jenseits des akademischen Kontexts bei aktuellen, politische Aktivitäten und Kämpfen, wie z.B. in der care-Bewegung, aktiv oder wollen diese vorantreiben.",
        second: "Hier geht’s zur Website der ",
        secondLink: "femphils*",
        secondSecond: "."
    },
    english: {
        arrow: "<< ",
        headline: "COOPERATIONS >>",
        secondHeadline: "femphils*",
        first: "The femphils* have developed the \"nocturnal idea\" of Nocturnal Unrest. The group is an association of feminist philosophers at the Goethe University Frankfurt who deal with philosophical theory and practice as well as socio-political questions from a feminist perspective. The femphils* understand feminist philosophy not only as a theoretical debate but as a perspective on the world that inspires action. Central to their treatment of philosophical questions is the queer-feminist perspective with which they analyse theorems but also intervene in the current practice of philosophical science. However, the femphils* see themselves as a group that is not self-contained and at odds in all respects; it is a group in which different perspectives and backgrounds converge. On the one hand, they try to intervene in the teaching offered at the university by organizing seminars, workshops or reading circles on feminist topics. On the other hand, the femphils* actively intervene in current political activities and struggles beyond the academic context or want to advance these, such as in the care-movement.",
        second: "Click here for the website of ",
        secondLink: "femphils*",
        secondSecond: "."
    }
}
export {femphilsContent}